import { RouteReuseStrategy, ActivatedRouteSnapshot, DetachedRouteHandle } from '@angular/router';

export class AppRouteReuseStrategy implements RouteReuseStrategy {
    private storedRoutes = new Map<string, DetachedRouteHandle>();

    shouldDetach(route: ActivatedRouteSnapshot): boolean {
        switch (route.routeConfig.path) {
            // Setups
            case 'setup-supplier-list': return true;
            case 'setup-customer-list': return true;
            case 'setup-item-list': return true;
            case 'setup-bank-list': return true;
            case 'setup-job-type-list': return true;
            case 'setup-job-department-list': return true;
            case 'setup-chart-of-accounts': return true;
            case 'setup-currency-list': return true;

            // Activity
            case 'activity-purchase-request-list': return true;
            case 'activity-purchase-order-list': return true;
            case 'activity-receiving-receipt-list': return true;
            case 'activity-disbursement-list': return true;
            case 'activity-payable-memo-list': return true;
            case 'activity-sales-order-list': return true;
            case 'activity-sales-invoice-list': return true;
            case 'activity-collection-list': return true;
            case 'activity-receivable-memo-list': return true;
            case 'activity-journal-voucher-list': return true;
            case 'activity-job-order-list': return true;
            case 'activity-production': return true;
            case 'activity-stock-in-list': return true;
            case 'activity-stock-out-list': return true;
            case 'activity-stock-transfer-list': return true;
            case 'activity-stock-count-list': return true;
            case 'activity-stock-withdrawal-list': return true;
            case 'activity-inventory-list': return true;
            case 'activity-bank-reconciliation': return true;
            case 'activity-POS': return true;
            case 'activity-mf-job-order-list': return true;

            // Reports
            case 'report-accounts-payable': return true;
            case 'report-accounts-receivable': return true;
            case 'report-inventory': return true;
            case 'report-production': return true;
            case 'report-financial-statement': return true;
            case 'report-mf-job-order': return true;

            // System
            case 'system-company-list': return true;
            case 'system-user-list': return true;
            case 'system-tables': return true;
            case 'system-settings': return true;

            default: return false;
        }
    }

    store(route: ActivatedRouteSnapshot, handle: DetachedRouteHandle): void {
        this.storedRoutes.set(route.routeConfig.path, handle);
    }

    shouldAttach(route: ActivatedRouteSnapshot): boolean {
        return !!route.routeConfig && !!this.storedRoutes.get(route.routeConfig.path);
    }

    retrieve(route: ActivatedRouteSnapshot): DetachedRouteHandle {
        return this.storedRoutes.get(route.routeConfig.path);
    }

    shouldReuseRoute(future: ActivatedRouteSnapshot, curr: ActivatedRouteSnapshot): boolean {
        return future.routeConfig === curr.routeConfig;
    }
}
