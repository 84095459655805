export class AppSettings {
    public APIURLHost = "https://liteclerk-api-mfcomputer.azurewebsites.net";
    // public APIURLHost = "https://liteclerkapi-colorideas.azurewebsites.net";
    // public APIURLHost = "https://liteclerk-api-snappy.azurewebsites.net";
    // public APIURLHost = "http://localhost:51111";
    // public APIURLHost = "https://10.13.88.134";
    // public APIURLHost = "https://easyfisdbapi";
    // public APIURLHost = "https://liteclerk-api-liteclerk.azurewebsites.net";
    // public APIURLHost = "http://localhost:8081";
    // public APIURLHost = "https://liteclerk-api-danenrico.azurewebsites.net";
}
